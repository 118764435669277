import * as React from "react"
import LangSwitcher from "./lang-switcher"

const Nav = () => (
  <nav className="flex justify-between items-center px-16 py-7 z-50 relative bg-teal-50">
    <div className="opacity-5 bg-gradient-to-r from-teal-700 to-teal-800 h-44 w-44 rounded-full absolute -top-20 -left-12" />
    <div className="logo text-teal-500 text-5xl">ranchi</div>
    <LangSwitcher title={"testing"} />
  </nav>
)

export default Nav
