import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
// import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"

const IndexPage = () => {
  const [val, setVal] = React.useState("test")

  const { t } = useTranslation()

  return (
    <Layout>
      <Helmet>
        <title>Welcome to ranchi</title>
      </Helmet>
      <section
        className="relative px-10 bg-teal-50 mx-auto"
        style={{ minHeight: `500px` }}
      >
        <h1 className="text-4xl pt-20 px-10 font-semibold">
          {t("Lunch should be easy, tasty and wallet friendly!")}
        </h1>
        <div className="absolute right-0 max-w-3xl opacity-20 md:opacity-100">
          <StaticImage src="../images/burger.svg" alt="burger" />
        </div>
        {/* <div className="flex">
        
        <StaticImage src="../images/eating.svg"/>
        </div>
        <StaticImage src="../images/food plate.svg"/>
        <StaticImage src="../images/app.svg"/> */}
        <div className="relative flex pt-12 pl-10 z-50">
          <div className="">
            <h2 className="py-2 text-teal-900 md:text-teal-600">
              {t("free lunches on us just by subscribing to our launch date!")}
            </h2>
            <div className="bg-white rounded px-5 py-3 shadow">
              <input
                className="focus:outline-none md:w-96"
                value={val}
                onChange={e => setVal(e.target.value)}
              />
              <button className="bg-gray-500 px-6 py-3 rounded bg-gradient-to-r from-teal-600 to-teal-800 text-teal-50">
                {t("Subscribe")}
              </button>
            </div>
            <h3 className="text-center py-2 text-teal-900 md:text-teal-600">
              {t("Who said, there ain't no such thing as a free lunch?")}
            </h3>
          </div>
        </div>
      </section>

      <section className="container mx-auto py-10 px-10">
        <h4 className="py-10 text-3xl font-semibold text-center">
          VALUE PROPS??
        </h4>
      </section>
      <section className="container mx-auto py-10 px-10">
        <h4 className="py-10 text-3xl font-semibold text-center">
          CTA FOR SOCIAL SHARING
        </h4>
      </section>
      <section className="container mx-auto py-10 px-10">
        <h4 className="py-10 text-3xl font-semibold text-center">
          WHY IT WORKS?
        </h4>
        <div className="flex justify-between items-center flex-wrap">
          <div>
            <StaticImage
              alt="book your dish"
              height={300}
              src="../images/app.svg"
            />
            <h5 className="text-2xl">{t("Book your dish")}</h5>
          </div>
          <div>
            <StaticImage
              alt="pick up your dish"
              height={300}
              src="../images/shop.svg"
            />
            <h5 className="text-2xl">{t("pickup the dish")}</h5>
          </div>
          <div>
            <StaticImage
              alt="enjoy your dish"
              height={300}
              src="../images/eating.svg"
            />
            <h5 className="text-2xl">{t("enjoy the dish")}</h5>
          </div>
        </div>

        {/* <StaticImage src="../images/food plate.svg" /> */}
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
