import React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

const LangSwitcher = () => {
  const { languages, originalPath, language } = useI18next()
  return (
    <header className="main-header">
      <ul className="languages flex uppercase text-coolGray-500">
        {languages.map((lng, index) => (
          <li key={lng}>
            {index !== 0 ? "/" : ""}
            <Link
              className={`mx-1 ${
                language === lng ? "text-coolGray-700 font-semibold" : ""
              }`}
              to={originalPath}
              language={lng}
            >
              {lng}
            </Link>
          </li>
        ))}
      </ul>
    </header>
  )
}

export default LangSwitcher
