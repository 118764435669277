import * as React from "react"
import { Link } from "gatsby"

const Footer = () => {
  const today = new Date()
  const links = [{ label: "imprint", to: "imprint" }]
  return (
    <div className="mt-20 mx-auto container text-coolGray-500 flex justify-between px-10 py-2 border-t border-coolGray-100">
      <div>copyright {today.getFullYear()} ranchiapp.com</div>
      <ul>
        {links.map(({ to, label }) => (
          <li key={to}>
            <Link to={to}>{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default Footer
